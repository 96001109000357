import is from "is_js";
import React, { Component } from "react";
import { connect } from "react-redux";
import { logout, set, update } from "../../Auth/authActions";
import API, { headers } from "../../utils/API";
import { HAButton } from "../../utils/components/HAButton";
import HAInput from "../../utils/components/HAInput";
import {
  getRoute,
  handleErrors,
  sendGAEvent,
  sendMetaEvent,
} from "../../utils/helper";
import Profile from "../Profile";
import HAModal from "../../utils/components/HAModal";
import { CSSTransition } from "react-transition-group";
import { toggleModal } from "../../App/appActions";
import DeleteAccountModal from "../components/DeleteAccountModal";

class ProfileInfo extends Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    defValues: {},
    optionals: ["phone"],
    isLoading: true,
    isButtonActive: false,
    isSending: false,
    isUpdatingAvatar: false,
    modalIsOpen: false,
  };

  configs = {
    headers: { ...headers, Authorization: `Bearer ${this.props.user.token}` },
  };

  componentDidMount = () => {
    if (`newEmail` in this.props.user) {
      const user = { ...this.props.user };
      delete user.newEmail;
      this.props.set(user);
    }

    const { firstName, lastName, email, phone } = this.props.user;
    const state = { firstName, lastName, email, phone: phone || "" };
    this.setState({ ...state, defValues: { ...state } }, () => {
      setTimeout(() => this.setState({ isLoading: false }), 20);
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.isSending !== this.state.isSending && this.state.isSending)
      this.props.hideInfoBanner();
  };

  handleChange = ({ target }) =>
    this.setState({ [target.name]: target.value }, this.checkChanges);

  checkChanges = () => {
    const keys = Object.keys(this.state.defValues);
    const empty = keys.filter(
      (key) =>
        (!this.state[key] || this.state[key] === "") &&
        !this.state.optionals.includes(key)
    );

    const changes = empty.length
      ? []
      : keys.filter(
          (key) =>
            this.state.defValues[key] !== this.state[key] && this.state[key]
        );

    const isButtonActive = Boolean(changes.length);

    this.setState({ isButtonActive });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ isSending: true }, () => {
      if (
        this.state.firstName !== this.state.defValues.firstName ||
        this.state.lastName !== this.state.defValues.lastName ||
        this.state.phone !== this.state.defValues.phone
      ) {
        sendGAEvent("event", "generate_lead");
        sendMetaEvent("track", "Lead");

        this.updateProfile();
      }
      if (this.state.email !== this.state.defValues.email) this.updateEmail();
    });
  };

  updateProfile = () => {
    const { firstName, lastName, phone } = this.state;
    const data = {
      firstName,
      lastName,
      phone: phone ? phone.replace(/\D/g, "") : "",
    };
    API.post("Account/UpdateProfile", data, this.configs)
      .then(({ data: res }) => {
        const { status } = res;
        if (status) {
          const defValues = {
            ...this.state.defValues,
            firstName,
            lastName,
            phone,
          };
          const user = {
            ...this.props.user,
            firstName,
            lastName,
            phone,
          };

          this.props.update(user);
          this.setState({ defValues }, () => {
            this.props.showInfoBanner("Profil güncellendi.", "success");
            this.setState({ isSending: false });
          });
        }
      })
      .catch((err) => {
        handleErrors(err);
        this.setState({ isSending: false });
      });
  };

  updateEmail = () => {
    const { email: newEmail } = this.state;
    const data = { newEmail };

    if (newEmail && is.email(newEmail)) {
      API.post("Account/ChangeEmail", data, this.configs)
        .then(({ data: res }) => {
          const { status } = res;
          if (status) {
            this.props.update({ newEmail });
            this.props.navigate(getRoute("confirm")?.link);
          } else {
            if (`friendlyMessage` in res?.info) {
              this.props.showInfoBanner(res.info.friendlyMessage, "error");
              this.setState({ isSending: false });
            }
          }
        })
        .catch((err) => {
          handleErrors(err);
          this.setState({ isSending: false });
        });
    } else {
      this.setState({ isSending: false }, () => {
        this.props.showInfoBanner("Geçersiz e-posta.", "error");
      });
    }
  };

  handleUploadAvatar = ({ target }) => {
    this.setState({ isUpdatingAvatar: true }, () => {
      const bodyFormData = new FormData(document.getElementById("avatar-form"));

      const file = target.files[0];

      bodyFormData.append("File", file);

      API.post("Account/UpdateProfilePhoto", bodyFormData, this.configs)
        .then(({ data: res }) => {
          const { status, info, data } = res;

          if (status) {
            const user = { ...this.props.user };
            user.avatar = data;
            this.props.update(user);
            this.props.showInfoBanner(
              "Profil fotoğrafı güncellendi.",
              "success"
            );
          } else {
            if (info && `friendlyMessage` in info) {
              this.props.showInfoBanner(info.friendlyMessage, "error");
            }
          }

          this.setState({ isUpdatingAvatar: false });
        })
        .catch((err) => {
          handleErrors(err);
          this.setState({ isUpdatingAvatar: false });
        });
    });
  };

  deleteAccount = () => {
    API.get(`Account/DeleteAccountPermanently?email=${this.props.user.email}`, {
      headers: {
        ...headers,
        Authorization: `Bearer ${this.props.user.token}`,
      },
    })
      .then((res) => {
        const { status, info } = res;

        if (status) {
          this.props.logout();
          this.setState({ modalIsOpen: false });
          this.props.showInfoBanner("Hesabınız silindi.", "success");
        } else {
          if (info && `friendlyMessage` in info) {
            this.props.showInfoBanner(info.friendlyMessage, "error");
          }
        }
      })
      .catch((err) => {
        handleErrors(err);
      });
  };

  render() {
    return (
      <Profile isLoading={this.state.isLoading}>
        <div className="page page--profile-info">
          <section className="page-section">
            <div className="row">
              <div className="col">
                <h6 className="section-title">Profil Bilgilerim</h6>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <form
                  className="avatar-area d-flex justify-content-start align-items-center"
                  id="avatar-form"
                >
                  <div className="avatar-holder rounded-circle overflow-hidden">
                    {this.props.user.avatar ? (
                      <img
                        src={this.props.user.avatar}
                        alt={`${this.props.user.fullName} Avatar`}
                        id="user-avatar"
                        className="mw-100"
                      />
                    ) : (
                      <svg
                        width="96"
                        height="80"
                        viewBox="0 0 96 80"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M48.0265 64.7995C65.8043 64.7995 78.9652 72.952 83.1045 80C91.1177 71.4793 96 60.0657 96 47.5477C96 21.3018 74.5075 0 47.9735 0C21.4925 0 0 21.3018 0 47.5477C0 60.0657 4.88226 71.4267 12.8425 79.9474C17.0348 72.952 30.2488 64.7995 48.0265 64.7995ZM65.9635 35.9237C65.9635 47.1269 58.1625 55.7002 48.0265 55.7002C37.8375 55.6476 30.0896 47.1269 30.0365 35.9237C29.9834 25.4043 37.9967 16.568 48.0265 16.568C58.0033 16.568 65.9635 25.4043 65.9635 35.9237Z"
                          fill="#031721"
                        />
                      </svg>
                    )}
                  </div>
                  <input
                    type="file"
                    name="File"
                    id="File"
                    className="d-none"
                    onChange={this.handleUploadAvatar}
                    disabled={this.state.isUpdatingAvatar}
                  />
                  <label
                    className="add-avatar-button"
                    htmlFor="File"
                    data-is-disabled={this.state.isUpdatingAvatar}
                  >
                    Profil Fotoğrafı Ekle
                  </label>
                </form>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <form onSubmit={this.handleSubmit} className="info-form">
                  <div className="row">
                    <div className="col-6">
                      <HAInput
                        name="firstName"
                        value={this.state.firstName}
                        label="Adınız"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="col-6">
                      <HAInput
                        name="lastName"
                        value={this.state.lastName}
                        label="Soyadınız"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <HAInput
                        type="email"
                        name="email"
                        value={this.state.email}
                        label="E-Posta Adresiniz"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="col">
                      <HAInput
                        type="tel"
                        name="phone"
                        value={this.state.phone}
                        label="Telefon Numaranız (Opsiyonel)"
                        onChange={this.handleChange}
                        formatNumber
                        format="(###) ### ####"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <HAButton
                        className="w-100 mt-1"
                        disabled={
                          !this.state.isButtonActive || this.state.isSending
                        }
                        action="submit"
                        type="alt"
                      >
                        Güncelle
                      </HAButton>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <HAButton
                        type="gray-empty"
                        className="w-100 logout-button"
                        onClick={this.props.logout}
                        disabled={this.state.isSending}
                      >
                        Çıkış Yap
                      </HAButton>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <HAButton
                        type="only-text"
                        onClick={() => this.setState({ modalIsOpen: true })}
                        className="w-100 mb-0 text-danger"
                      >
                        Hesabımı Sil
                      </HAButton>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>

        <CSSTransition
          in={this.state.modalIsOpen}
          timeout={300}
          unmountOnExit
          classNames="HAModal"
        >
          <HAModal closeModal={() => this.setState({ modalIsOpen: false })}>
            <DeleteAccountModal
              closeModal={() => this.setState({ modalIsOpen: false })}
              deleteAccount={this.deleteAccount}
            />
          </HAModal>
        </CSSTransition>
      </Profile>
    );
  }
}

const mapPropsToState = (state) => ({
  isModalOn: state.app.app.isModalOn,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModal: (status = null) => dispatch(toggleModal(status)),
  logout: () => dispatch(logout()),
  update: (user) => dispatch(update(user)),
  set: (user) => dispatch(set(user)),
});

export default connect(mapPropsToState, mapDispatchToProps)(ProfileInfo);
