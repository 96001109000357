import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { HAButton } from "../../utils/components/HAButton";
import HAInput from "../../utils/components/HAInput";
import { InfoArea } from "../../utils/components/InfoArea";
import { Auth } from "../Auth";
import { set } from "../authActions";
import API, { headers } from "../../utils/API";
import { getLoginData, getRoute, handleErrors, sendGAEvent, sendMetaEvent } from "../../utils/helper";

const ConfirmMail = ({ prevLocation, user, set, showInfoBanner }) => {
  const TYPES = {
    newEmail: {
      url: "Account/ConfirmEmailChange",
      redirect: getRoute("profile-info")?.link,
      response: `E-Posta adresiniz başarıyla değiştirildi.`,
    },
    email: {
      url: "Account/ConfirmEmail",
      redirect: getRoute("explore")?.link,
      response: `E-Posta adresiniz başarıyla doğrulandı.`,
    },
    password: {
      url: "Account/ValidateForgetPasswordRenewalCode",
      redirect: getRoute("resetpass")?.link,
    },
  };

  const confirmType = user.isLoggedIn
    ? `newEmail` in user
      ? `newEmail`
      : `email`
    : `password`;

  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");

  const [error, setError] = useState("");
  const [isSending, setIsSending] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let email = "";
    if (user.isLoggedIn) {
      email = user.newEmail || user.email;

      if (email) setEmail(email);
    } else if (location && location.search) {
      email = location.search.split("email=");
      if (email) {
        email = email[1].split("&");
        if (email) {
          email = email[0];

          if (email) setEmail(email);
        }
      }
    } else navigate(getRoute("explore")?.link);

    return () => {
      if ("newEmail" in user) {
        const newUser = { ...user, email };
        delete newUser.newEmail;
        set(newUser);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.isLoggedIn && !user.newEmail && !user.email) navigate(getRoute("explore")?.link);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.isLoggedIn]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (code && email) setIsSending(true);
    const data = {
      email: email,
      code: String(code).trim(),
    };

    if (confirmType === "password") {
      sendGAEvent("event", "generate_lead");
      sendMetaEvent("track", "Lead");

      API.post(TYPES.password.url, data, {
        headers: {
          ...headers,
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((res) => {
          const { info, data: check } = res.data;
          if (check) {
            navigate(
              TYPES.password.redirect + `?email=${data.email}&code=${data.code}`,
            );
          } else {
            setIsSending(false);
            setError(
              info?.friendlyMessage || `Yanlış bir doğrulama kodu girdiniz.`,
            );
          }
        })
        .catch((err) => {
          setIsSending(false);
          handleErrors(err);
        });
    } else {
      console.log({ confirmType })
      const url = TYPES[confirmType].url;

      const params = {
        code: data.code,
      };

      if (confirmType === "newEmail") {
        params.newEmail = data.email;
      } else {
        params.email = data.email;
      }

      sendGAEvent("event", "generate_lead");
      sendMetaEvent("track", "Lead");

      API.post(url, params, {
        headers: {
          ...headers,
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((res) => {
          const { status, info, data } = res.data;
          if (status) {
            console.log({ data })
            const user = getLoginData(data);
            const oldUserData = { ...user };

            console.log({ oldUserData }, "delete öncesi")
            if (confirmType === "newEmail") {
              delete oldUserData.newEmail;
            }
            console.log({ oldUserData }, "delete sonrası")


            set({ ...oldUserData, ...user });

            showInfoBanner(
              info?.friendlyMessage || TYPES[confirmType].response,
              "success",
            );

            navigate(TYPES[confirmType].redirect);
          } else {
            setIsSending(false);
            setError(
              info?.friendlyMessage || `Yanlış bir doğrulama kodu girdiniz.`,
            );
          }
        })
        .catch((err) => {
          setIsSending(false);
          handleErrors(err);
        });
    }
  };

  return (
    <Auth prevLocation={prevLocation}>
      <div className="AuthBox page-fix">
        <h6 className="title">Doğrulama Kodu</h6>
        <p className="desc">
          Lütfen E-Posta ile gelen doğrulama kodunuz giriniz.
        </p>
        {error && <InfoArea type="error">{error}</InfoArea>}
        <form action="#!" onSubmit={handleSubmit}>
          <HAInput
            type="text"
            name="code"
            value={code}
            label="Doğrulama Kodu"
            onChange={({ target }) => setCode(target.value)}
          />
          <HAButton
            className="w-100 login-button"
            disabled={!code}
            action="submit"
            isLoading={isSending}
          >
            Gönder
          </HAButton>
        </form>
      </div>
    </Auth>
  );
};

const mapDispatchToProps = (dispatch) => ({
  set: (user) => dispatch(set(user)),
});

export default connect(null, mapDispatchToProps)(ConfirmMail);
